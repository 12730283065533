<template>
  <div>
    <div
      style="
        width: 82.5%;
        margin-top: 160px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      "
    >
      <h1 style="font-size: 30px">
        {{ items.headline }}
      </h1>
      <div style="height: 24px"></div>
      <template v-for="(item, index) in items.text">
        <p :key="index" style="text-indent: 2em;">
          {{ item }}
        </p>
      </template>
      <div id="parent">
        <div
          v-for="img in imgArr"
          :key="img"
          style="
            display: inline-block;
            width: 100%;
            float: left;
            padding-bottom: 9999px;
            margin-bottom: -9999px;
          "
        >
          <v-img :src="img"></v-img>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import data from "./data";
export default {
  data() {
    return {
      items: {
        headline: "",
        text: [],
        imgArr: [],
      },
    };
  },
  created() {
    let i = this.$route.query;
    Object.assign(this.items, data[i.bbs]);
    this.imgArr = [
      require("@/assets/img/detail/" + this.items.headline + ".jpg"),
    ];
  },
  watch: {
    $route: {
      handler: function (v) {
        let i = v.query;
        Object.assign(this.items, data[i.bbs]);
        this.imgArr = [
          require("@/assets/img/detail/" + this.items.headline + ".jpg"),
        ];
      },
    },
  },
  mounted() {
    this.$parent.$parent.color = true;
  }
};
</script>

<style>
p {
  line-height: 1.5em;
}
#parent {
  overflow: hidden;
}
</style>